import { BreadcrumbJsonLd, LogoJsonLd, NextSeo } from 'next-seo';
import NextHead from 'next/head';
import { useRouter } from 'next/router';
import React, { useContext, useEffect } from 'react';
import TagManager from 'react-gtm-module';

import { PageContext } from '../../context/PageContext';
import { getBreadCrumbForPath } from '../../helpers/sitemap/getBreadCrumbForPath';
import { getURLForPath } from '../../helpers/sitemap/getURLForPath';
import { LanguageType } from '../../languages';
import { ConfigType } from '../../queries/config';
import { PageType } from '../../queries/page';
import { SitemapType } from '../../queries/sitemap';
import { ScriptJsonLd } from './ScriptJsonLd';

export type SeoProps = {
  config?: ConfigType;
  page?: PageType;
  sitemap?: SitemapType;
  preview?: boolean;
};

export const Seo = ({ config, page, sitemap, preview }: SeoProps) => {
  const router = useRouter();
  const { sitemapItem } = useContext(PageContext);

  useEffect(() => {
    if (preview) return;
    if (
      config?.integrations?.gtmid &&
      process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
    )
      TagManager.initialize({ gtmId: config?.integrations?.gtmid });
  }, [config?.integrations?.gtmid, preview]);

  if (!config?.seo || !page || !sitemap || !sitemapItem) return null;

  const pagePath = router.asPath;
  const locale = router.locale as LanguageType;
  const sitemapItemPaths = sitemapItem?.paths;
  const sitemapItemExcludedPaths = sitemapItem?.excludeFromSitemap;

  const baseUrl = `https://${config?.general?.domain}`;
  const seoTitle = `${preview ? 'Preview mode 👀 - ' : ''}${
    page.seo?.title || page.title
  }`;
  const seoDescription =
    page.description || page.seo?.description || config?.seo?.description;
  const seoCanonical = getURLForPath(config?.general?.domain, pagePath, locale);

  // for jobs, get the job id from the page slug
  if (page._id === 'page_job') {
    const pathSlugs = pagePath.split('/');
    const finalSlug = pathSlugs[pathSlugs.length - 1];
    const jobId = finalSlug.split('-').slice(-1)[0];
    page._id = `workable_job_${jobId}`;
  }
  const dynamicOGImageURL = `${baseUrl}/api/opengraph-image?id=${page._id}&language=${locale}`;

  const seoImage = {
    url: dynamicOGImageURL,
    width: 1200,
    height: 630,
    alt: '',
  };

  const brandJsonLd = {
    '@context': 'https://schema.org',
    '@type': 'Brand',
    name: config?.general?.name,
    description: config?.seo?.description,
    logo: `${baseUrl}/logo.svg`,
    URL: baseUrl,
    sameAs: config?.social?.socials,
  };

  const breadCrumb = getBreadCrumbForPath(pagePath, sitemap, locale);

  const excludeFromSitemap = sitemapItem?.excludeFromSitemap[locale];

  return (
    <>
      <NextSeo
        title={seoTitle}
        noindex={excludeFromSitemap === true}
        nofollow={excludeFromSitemap === true}
        titleTemplate={`%s - ${config?.general?.name}`}
        description={seoDescription}
        canonical={seoCanonical}
        languageAlternates={
          sitemapItemPaths
            ? Object.entries(sitemapItemPaths)
                .map(([locale, pagePath]) => {
                  if (sitemapItemExcludedPaths[locale]) return null;
                  return {
                    hrefLang: locale,
                    href: getURLForPath(
                      config?.general?.domain,
                      pagePath,
                      locale as LanguageType,
                    ),
                  };
                })
                .filter(Boolean)
            : null
        }
        openGraph={{
          type: 'website',
          url: seoCanonical,
          title: seoTitle,
          description: seoDescription,
          images: [seoImage],
          site_name: config?.general?.name,
        }}
        twitter={{
          handle: config?.social?.twitter?.handle,
          site: config?.social?.twitter?.url,
          cardType: 'summary_large_image',
        }}
      />

      <LogoJsonLd logo={`${baseUrl}/logo.svg`} url={baseUrl} />

      {Boolean(breadCrumb?.length) && (
        <BreadcrumbJsonLd
          itemListElements={breadCrumb.map(({ title, path }, index) => ({
            position: index + 1,
            name: title,
            item: getURLForPath(config?.general?.domain, path, locale),
          }))}
        />
      )}

      <NextHead>
        <ScriptJsonLd data={brandJsonLd} />
      </NextHead>
    </>
  );
};
